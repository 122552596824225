import * as type from 'constants/action_types'
import * as accountActionType from 'constants/action_types/accounts'
import { RECEIVE_DELETE_TEAM_MEMBER } from 'constants/action_types/team'
import { RESET_GLOBAL_DATA } from 'constants/action_types/global'
import { SET_FEATURE_LIMITS } from 'constants/action_types/accounts/featureLimits'
import { INITIAL_GLOBAL_DATA_STATE } from 'constants/globalData'

import { removeFromArray } from 'helpers/arrays'
import { getShowState } from 'helpers/core'
import { getGlobalData } from 'helpers/storage'

// **********************
// Using this reducer as a global APP UI State for now
// ***********************

export const initialState = {
  ...INITIAL_GLOBAL_DATA_STATE,
  ...getGlobalData(),
  modal: null
}

function globalData(state = initialState, action) {
  switch (action.type) {
    case type.PENDING_GLOBAL_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: true
        }
      }
    case type.COMPLETED_GLOBAL_ACTION:
      const completedKey = action.payload.key || action.payload
      return {
        ...state,
        [completedKey]: {
          ...state[completedKey],
          isLoading: false,
          completed: true
        }
      }
    case type.RESET_GLOBAL_ACTION:
      const key = action.payload.key || action.payload
      return {
        ...state,
        [key]: {
          ...state[key],
          isLoading: false,
          completed: false,
          error:
            action.payload.success !== undefined
              ? !action.payload.success
              : state[key].error
        }
      }
    case type.LOAD_GLOBAL_DATA:
      return {
        ...state,
        ...action.payload
      }
    case type.SET_GLOBAL_DATA:
      return {
        ...state,
        ...action.payload
      }
    case type.SET_GLOBAL_MODAL:
      return {
        ...state,
        modal: action.payload || null
      }
    case type.SET_GLOBAL_MODAL_METADATA:
      return {
        ...state,
        modalMetadata: action.payload || null
      }
    case type.ADD_TO_GLOBAL_TRACKER:
      return {
        ...state,
        allTrackedProducts: state.allTrackedProducts.concat(action.payload)
      }
    case type.REMOVE_FROM_GLOBAL_TRACKER: {
      const updatedTrackedProducts = removeFromArray(
        state.allTrackedProducts,
        action.payload
      )
      return {
        ...state,
        allTrackedProducts: updatedTrackedProducts
      }
    }
    case type.SET_NOTIFICATION:
      return {
        ...state,
        notices: [].concat(action.data)
      }
    case type.SET_SUBSCRIPTION_INFO:
      return {
        ...state,
        ...action.payload
      }
    case type.UPDATE_FEATURE_ACCESS:
      return {
        ...state,
        featureAccess: {
          ...state.featureAccess,
          [action.payload.prop]: action.payload.access,
          remainingSearches: {
            ...state.featureAccess.remainingSearches,
            [action.payload.prop]: action.payload.searches
          }
        }
      }
    case type.TOGGLE_JSPUP_WARNING:
      return {
        ...state,
        onboardingInfo: {
          ...state.onboardingInfo,
          jspupWarningViewed: action.payload
        }
      }
    case type.TOGGLE_PROFIT_CALCULATOR: {
      const newShowState = getShowState(
        state.showProfitCalculator,
        action.payload.show
      )
      return {
        ...state,
        showProfitCalculator: newShowState,
        selectedProduct: action.payload.product
      }
    }
    case type.TOGGLE_VARIANT_MODAL: {
      const newShowState = getShowState(state.showVariantModal)
      return {
        ...state,
        showVariantModal: newShowState,
        variants: action.payload
      }
    }
    case type.UPDATE_PENDO: {
      return {
        ...state,
        pendo: {
          visitor: {
            ...state.pendo.visitor,
            ...action.payload.visitor
          },
          account: {
            ...state.pendo.account,
            ...action.payload.account
          }
        }
      }
    }
    case type.SET_SELLER_PLANS:
      return {
        ...state,
        sellerPlans: {
          ...state.sellerPlans,
          ...action.payload.data
        }
      }
    case accountActionType.ACCOUNT_AMAZON_SELLER_ACCOUNT_CREATE_COMPLETE_STATUS:
      // we only care if this was a successful upgrade
      if (!action.payload.isUpgrade || !action.payload.success) {
        return state
      }

      // if the user has just upgraded - we want to update their membership type
      return {
        ...state,
        membershipInfo: {
          ...state.membershipInfo,
          has_launch_access: true
        }
      }
    case type.LOADED_FEATURE_FLAGS:
      // save the feature flags from the sign in request
      return {
        ...state,
        flagData: action.payload.flagData
      }
    case accountActionType.ACCOUNT_PHOTO_DELETED:
      return {
        ...state,
        user: {
          ...state.user,
          account_photo: null
        }
      }
    case accountActionType.ACCOUNT_PHOTO_UPLOADED:
      return {
        ...state,
        user: {
          ...state.user,
          account_photo: action.payload
        }
      }
    case accountActionType.ACCOUNT_SET_LANGUAGE:
      const newLang = action.payload.language
      return {
        ...state,
        user: {
          ...state.user,
          selected_language: newLang
        }
      }
    case RESET_GLOBAL_DATA:
      return { ...INITIAL_GLOBAL_DATA_STATE, appType: state.appType }
    case RECEIVE_DELETE_TEAM_MEMBER:
      return {
        ...state,
        membershipInfo: {
          ...state.membershipInfo,
          users: state.membershipInfo.users.map(user => {
            if (user.email !== action.payload.user.email) return user

            return {
              ...user,
              teams: user.teams.filter(
                team => team.id !== action.payload.teamId
              )
            }
          })
        }
      }
    // This specifically handles setting limit values for search based features & limits
    case SET_FEATURE_LIMITS:
      return {
        ...state,
        featureLimits: {
          ...state.featureLimits,
          [action.payload.feature]: {
            ...state.featureLimits[action.payload.feature],
            featureLimit: action.payload.featureLimits.limitMax,
            featureUsageCount: action.payload.featureLimits.limitCount
          }
        }
      }
    case type.DECREMENT_FEATURE_USAGE_COUNT:
      return {
        ...state,
        featureLimits: {
          ...state.featureLimits,
          [action.payload.feature]: {
            ...state.featureLimits[action.payload.feature],
            featureUsageCount:
              state.featureLimits[action.payload.feature].featureUsageCount - 1
          }
        }
      }
    case accountActionType.UPDATE_USER_INFO:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        }
      }
    case type.UPDATE_SEGMENT_SUBSCRIPTION:
      return {
        ...state,
        membershipInfo: {
          ...state.membershipInfo,
          segment_subscription: {
            ...state.membershipInfo.segment_subscription,
            subscription_item_id:
              action.payload.id ||
              state.membershipInfo.segment_subscription.subscription_item_id,
            segment_purchased: action.payload.segmentPurchased
          }
        }
      }

    default:
      return state
  }
}

export default globalData
